.therpaymodel {
    width: 474px;
    height: 496px;

    border-radius: 15px;

    .top-title-name {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        width: 474px;
        height: 65px;
        top: 202px;
        left: 483px;
        gap: 0px;
        border-radius: 15px 15px 0px 0px;
   
        background:  #010101;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    textarea {
        width: 421px;
        height: 295px;
        margin: 23px 23px;
        border-radius: 5px;
        padding: 1rem;
        border: 1px solid #E5E5E5;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #5F6163;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }

    label {
        width: 421px;
        height: 47px;

        margin: 23px 23px;

        border-radius: 5px;
        background: #C51E25;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;

        cursor: pointer;
    }
}

.ReactModal__Overlay {

    z-index: 4 !important;
}