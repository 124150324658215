.bookinginnputsection {
    height: 100%;

    display: flex;
    align-items: flex-start;

    input[type='radio'] {
        accent-color:  #C51E25;
    }

    .rightside {
        padding-left: 1rem;
        width: calc(85% - 0px);
        overflow: scroll;
        height: 100%;
        margin: auto;
        padding-top: 3rem;
    }

    .leftsidesetting {
        padding: 1rem;
        border-right: 1px solid #E6E6E6;
        padding-left: 0;
        width: 189px;
        padding-top: 0;
        height: 100%;

        .settingName {
            //styleName: xl/Semibold;

            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #08182E;

        }

        .listmenuNotseleted {
            background: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 39px;
            padding: 0 1rem;
            border-radius: 6px;
            margin-top: 1.2rem;

            &:hover {
                transform: scale(1.02);
            }

            svg {
                margin-right: 6px;
                stroke: #384455 !important;
            }

            div {

                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: #384455;
            }
        }

        .listmenuseleted {
            background:  #C51E25;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 39px;
            padding: 0 1rem;
            border-radius: 6px;
            margin-top: 1.2rem;

            &:hover {
                transform: scale(1.02);
            }

            svg {
                margin-right: 6px;
                stroke: #fff !important;
            }

            div {

                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: white;
            }
        }
    }

    .top_image-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .labelsavelist {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 3rem;
    }

    .savelabel {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #fff;
        width: 102px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #37B12B;

        border-radius: 6px;

        &:hover {
            transform: scale(1.02);
        }

        svg {
            height: 16px;
            margin-right: 2px;
        }

    }

    .clearlabel {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #272727;
        width: 102px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D1D7E1;
        border-radius: 6px;

        &:hover {
            transform: scale(1.02);
        }

        svg {
            height: 16px;
            margin-right: 2px;
        }
    }

    .top_inner-image-section {
        display: flex;
        align-items: center;
    }

    .textSectionTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .infotext {

        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;

    }

    .editlabel {
        width: 85px;
        justify-content: center;
        height: 33px;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #473D3E;
        border: 1px solid #D7D7D7;
        display: flex;
        align-items: center;


        svg {
            margin-right: 4px;
        }
    }

    .topName-section {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;

        .name {

            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #08182E;

        }

        .id {
            color: #473D3E;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            margin-left: 13px;
            text-align: left;
            background: #F8F8F8;
            padding: 3px 8px;
            width: max-content;
            border-radius: 6px;
        }
    }

    .short-drop-down-orange {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0px 15px;
        background:  #C51E25;
        border-radius: 6px;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
        color: #fff;

        height: 35px;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }



        .line {
            height: 24px;
            width: 1.26px;
            background: #fff;
            height: 24px;
            margin-left: 15px;
            margin-right: 5px;
        }
    }

    .deletLabel {

        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #473D3E;
        width: 98px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 12px;
        background: #F8F8F8;
        border-radius: 6px;

        &:hover {
            transform: scale(1.02);
        }

    }

    .Innerinnputsection {
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .subdivision {
        width: 48%;
    }
}