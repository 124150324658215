.consultationSessionPage {
    height: calc(100vh - 107px);
    background: transparent;
    margin: 2rem;

    .subTable {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        padding: 0 1rem;
        margin-top: 10px;

        .labeltab {

            font-size: 12px;
            font-weight: 500;
            line-height: 21.78px;
            text-align: center;
            color: #0A162B;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            height: 34px;
            border-radius: 6px;
            cursor: pointer;

            svg {
                margin-right: 5px;
            }

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }
        }
    }

    .pageinsied {
        background: white;
        height: calc(100%);
        overflow: hidden;

    }

    .tab-section {
        padding: 1rem;
        padding-bottom: 0.5rem;
        overflow: scroll;
    }

    .bottomlabel {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .back {
        background: #7a7a7a30;
        height: 40px;
        border-radius: 6px;
        padding: 0 1rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        cursor: pointer;
        text-align: left;
        color: rgb(122, 122, 122);
        width: max-content;
        display: flex;
        align-items: center;
    }

    .next {
        background:  #C51E25;
        height: 40px;
        border-radius: 6px;
        padding: 0 1rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        cursor: pointer;
        text-align: left;
        color: white;
        width: max-content;
        display: flex;
        align-items: center;

    }

    .tab-section {
        .labeltextFristTrue {

            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;
            white-space: nowrap;
            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 31px;
            background:  #C51E25;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background:  #C51E25;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: 20px;
                right: 0;

                height: 19px;
            }

            &:before {

                transform: skew(45deg);
                top: 0;
            }

            &:after {

                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #fff;

                position: relative;
                z-index: 10;
            }
        }



        .labeltextFrist {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;

            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 31px;
            background: #FFDCCD;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background: #FFDCCD;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: 20px;
                right: 0;

                height: 19px;
            }

            &:before {

                transform: skew(45deg);
                top: 0;
            }

            &:after {

                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #B23800;

                position: relative;
                z-index: 10;
            }
        }

        .labeltext {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;

            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 34px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background: #FFDCCD;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;

                height: 18px;
            }

            &:before {
                box-shadow: -1px 1px 0 #FFDCCD, -2px 2px 0 #FFDCCD, -3px 3px 0 #FFDCCD, -4px 4px 0 #FFDCCD;
                transform: skew(45deg);
                top: 0;
            }

            &:after {
                box-shadow: 1px 1px 0 #FFDCCD, 2px 2px 0 #FFDCCD, 3px 3px 0 #FFDCCD, 4px 4px 0 #FFDCCD;
                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #B23800;

                position: relative;
                z-index: 10;
            }
        }

        .labeltextTrue {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;

            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 34px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background:  #C51E25;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;

                height: 18px;
            }

            &:before {
                box-shadow: -1px 1px 0  #C51E25, -2px 2px 0  #C51E25, -3px 3px 0  #C51E25, -4px 4px 0  #C51E25;
                transform: skew(45deg);
                top: 0;
            }

            &:after {
                box-shadow: 1px 1px 0  #C51E25, 2px 2px 0  #C51E25, 3px 3px 0  #C51E25, 4px 4px 0  #C51E25;
                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #fff;

                position: relative;
                z-index: 10;
            }
        }







        .labeltextlast {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;

            white-space: nowrap;
            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 31px;
            background: #FFDCCD;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background: #FFDCCD;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: -6px;
                right: 7px;

                height: 18px;
            }

            &:before {

                transform: skew(45deg);
                top: 0;
            }

            &:after {

                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #B23800;

                position: relative;
                z-index: 10;
            }
        }

        .labeltextlastTrue {

            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: white;
            white-space: nowrap;
            color: white;
            width: max-content;
            padding: 0 1.3rem;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 31px;
            background:  #C51E25;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

            &::before,
            &::after {
                background:  #C51E25;
                border-radius: 2px;
                content: "";
                display: block;
                position: absolute;
                left: -6px;
                right: 7px;

                height: 18px;
            }

            &:before {

                transform: skew(45deg);
                top: 0;
            }

            &:after {

                transform: skew(-45deg);
                bottom: 0;
            }

            svg {
                margin-right: 10px;
                position: relative;
                z-index: 10;
            }

            div {
                color: #fff;

                position: relative;
                z-index: 10;
            }
        }

    }












    .userinfo-section {
        border-bottom: 1px solid #D1D7E1;
        padding: 1rem;

        .img-pic {
            display: flex;
            height: 78px;
            width: 78px;
            border-radius: 50rem;
            background: gray;
        }

        input {

            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #1F2026;
            height: 34px;
            border: 1px solid #D0D5DD;
            padding-left: 0.7rem;
            border-radius: 6px;
            width: 10rem;
        }

        .samlltext {

            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;

            color: #7587A7;

        }

        .detailtext {

            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #1F2026;

        }

        .name {

            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #08182E;

        }

        .flex-box-sec {
            display: flex;
            align-items: center;
        }

        .gapflex {
            gap: 2rem;
        }

        .id {

            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #1F2026;
            background: #F5F7FA;
            border-radius: 6px;
            border: 1px solid #DEE3EB;
            height: 36px;
            padding: 0 0.8rem;
            width: max-content;
            display: flex;
            align-items: center;
            margin-left: 0.7rem;
        }

        .statuslabel {

            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #7587A7;

        }

        .label {

            font-size: 14px;
            font-weight: 400;
            line-height: 21.78px;
            text-align: center;
            color: #5E7398;
            height: 34px;
            padding: 0 1rem;
            background: #EFF0F6;
            width: max-content;
            border-radius: 6px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }

        }
    }


}


@media only screen and (max-width: 1400px) {

    .consultationSessionPage .userinfo-section .gapflex {
        gap: 1rem;
    }

    .consultationSessionPage .userinfo-section input {
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #1F2026;
        height: 34px;
        border: 1px solid #D0D5DD;
        padding-left: 0.7rem;
        border-radius: 6px;
        width: 8rem;
    }
}