.innerSectionSession {
    height: calc(100vh - 23rem);
    background: white;
    padding: 21px;
    /* padding-top: 0; */
    padding-top: 2rem;

    overflow: scroll;
    margin-top: 1.5rem;
    position: relative;

    .widgetparent {
        border: 1px solid #E9EBF3;
        padding: 2rem;
        margin-bottom: 1.5rem;
        border-radius: 10px;
        position: relative;
    }

    .namewidget {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #010101;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }

    }

    .widgetparent {
        border: 1px solid #E9EBF3;
        padding: 2rem;
        margin-bottom: 1.5rem;
        border-radius: 10px;
        position: relative;
    }

    .saveSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            border: 1px solid #E9EBF3;
            width: 158px;
            height: 36px;

            border-radius: 5;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 1rem;

            &:hover {
                transform: scale(1.03);
            }

        }
    }

    .questionsection {
        margin-top: 2rem;
        position: relative;

        &:nth-child(1) {
            //margin-top: 0rem;
        }

        .queTitledrop {
            border: 1px solid #E9EBF3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            height: 46px;
            padding: 0 10px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            width: 100%;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .dropdonwlistdata {
            border: 1px solid #E9EBF3;
            border-radius: 10px;
            border-radius: 10px;
            width: 100%;
            padding: 1.5rem;
            position: absolute;
            background: white;
            z-index: 1;
            top: 4.5rem;



            div {
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #010101;
                cursor: pointer;
                margin-top: 1rem;

                &:nth-child(1) {
                    margin-top: 0rem;
                }

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        .queTitle {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #010101;
            margin-bottom: 1rem;
        }

        .queAnswer {
            border: 1px solid #E9EBF3;
            height: 36px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #010101;
            width: 158px;
        }

        .queAnswerinput {
            border: 1px solid #E9EBF3;
            height: 46px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #010101;
            width: 100%;

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 0px !important;
            }
        }
    }

    img {
        width: 47px;
        height: 47px;
        border-radius: 54rem;

        margin-right: 14px;
    }
}


.deitpaldroplist {
    background: #FFFFFF;
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;

    border: 1px solid #E5E5E5;
    margin-top: 1rem;

    .name {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #010101;

    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    input {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;

        color: #010101;
        border: 0px solid;
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        background: #FFF7F3;
        height: 22px;
        padding-left: 6px;

        &::placeholder {
            //text-align: right;
        }

    }
}


.handle-table-editing {
    position: relative;
    z-index: 1;
    top: -6px;
    overflow: scroll;
    height: calc(100vh - 20rem);


    .g-table-sec {


        overflow: auto;
        height: 100%;
        padding-top: 0rem;
        background-color: white;
    }

    .table-section {

        height: 100%;
    }

    table {
        border-spacing: 0px 11px;
        border-collapse: separate;
        min-width: 240%;
        table-layout: fixed;
        width: max-content;


        th {
            border-collapse: collapse;
            max-width: 200px;
            min-width: 40px;
            padding: 0 1rem;
            position: sticky;
            top: 0;
            background: #C51E25;

            z-index: 1;
            color: white;
        }
    }

    tr {
        // display: grid;
        height: 4rem;


        background: #FBFBFB;

        &:hover {
            transform: scale(1.01);
        }

        &:nth-child(odd) {
            background: #FBFBFB;
            // background: red;
        }

        &:nth-child(even) {
            background: #fff;
        }
    }

    td {
        padding: 1rem 1rem;
        position: relative;
        max-width: 200px;
        min-width: 40px;
        font-size: 13px;

        // border: 1px solid #e5e5e5;

        // white-space: normal;
        // word-wrap: break-word;

        // position: relative;
        // display: flex;
        // align-items: center;
        // border-right: none;
        // border-top: none;
        // font-size: 0.85rem;


        &:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
        }

        &:nth-last-child(1) {
            border-radius: 0px 10px 0px 10px;
        }
    }

    thead {


        th {

            padding: 0 1rem;


            &:nth-child(1) {
                border-radius: 0px 0px 0px 10px;
            }

            &:nth-last-child(1) {
                border-radius: 0px 0px 10px 0px;
            }
        }

        tr {

            //display: grid;

            &:nth-child(1) {

                font-family: Montserrat;
                font-size: 12px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
                color: white;

                z-index: 9;
                white-space: nowrap;
                height: 40px;
                background: #010101;
                position: relative;
                top: -14px;

                &:hover {
                    transform: scale(1) !important;
                }

            }
        }
    }
}

.wedegettabs {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    overflow: scroll;

    .lables {
        padding: 0.3rem 1.3rem;
        height: 41px;
        cursor: pointer;
        border-radius: 35px;
        opacity: 0.15px;
        background: rgba(252, 79, 0, 0.0588235294);
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color:  #C51E25;

        &:hover {
            transform: scale(1.01);
        }

    }
}

.dropdownkitechitem {
    height: 165px;
    border-radius: 5px;

    border: 1px solid #E5E5E5;
    padding: 1.5rem 0px;
    background: white;
    width: 100%;
    margin-top: 13px;
    overflow: scroll;

    div {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #5F6163;
        padding: 0px 1.5rem;
        height: 42px;
        cursor: pointer;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: #01010124;
        }

    }
}

.labelsectedTheraply {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    label {
        width: 102px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 12px;
        background: #FFFFFF;
        color: #010101;

        gap: 0px;
     
        border: 1px solid #E5E5E5
    }


}

.slectedtheryauotbook {
    margin-top: 1rem;

    .titleseleted {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #5F6163;
        margin-bottom: 0.4rem;
        margin-top: 1.2rem;
    }

    .dropdwonlist {
        border-radius: 5px;
        background: #fff;
        padding: 1rem;
        position: absolute;
        top: 41px;
        width: 100%;
        left: 0;
        margin: auto;
        right: 0;
        z-index: 1;
        white-space: break-spaces;
        border: 1px solid #E5E5E5;
    }

    .toggledrop {
        position: absolute;
        z-index: 9999;
        width: 100%;

    }

    .dateinput {
        height: 40px;
        position: absolute;
        width: 99%;
        left: 1px;
        padding-left: 12px;
        border: 0px solid;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F6163;
        font-style: normal;
        font-weight: 400;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
            border: 0px solid;
        }
    }

    .dropdown-planb {
        border-radius: 5px;
        background: #fff;
        height: 38px;
        margin-bottom: 13px;
        width: 100%;
        color: #5F6163;
        font-family: Montserrat;
        padding: 0 0.7rem;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid #E5E5E5;

        svg {
            margin-left: 15px;
        }
    }

    .titleseleted {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #5F6163;
        margin-bottom: 0.4rem;
        margin-top: 1.2rem;
    }

    .dropdownParentSection {
        position: relative;
        width: 206px;
    }

    .listitemName {
        display: flex;
        align-items: center;
        color: #5F6163;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1rem;
        cursor: pointer;

        &:nth-last-child(1) {
            margin-bottom: 0rem;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        &:hover {
            transform: scale(1.01);
        }

    }

}