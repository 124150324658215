.Billing {
    background: #fff;

    margin: 2rem;
    padding: 1rem;
    border-radius: 6px;

    height: calc(100vh - 171px);

    .globalLoading {
        position: fixed;
        z-index: 999;
        inset: 0;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 244px);
    }

    .globalLoading>img {
        width: 200px;
        height: 200px;
        transition: all linear 0.3s;
        animation: heartbeat 1s infinite;
    }

    @keyframes heartbeat {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .playmentDrawer {
        width: 520px;
        position: absolute;
        border-radius: 10px;
        padding: 2rem;
        border: 1px solid #E8E8E9;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: white;
        z-index: 9;
        right: 0;
        top: 38px;

        .topSectionButtom {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-top: 1.4rem;
        }

        .submit {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 16px;
            background:  #C51E25;
            border-radius: 6px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            color: #fff;
            height: 35px;
            cursor: pointer;
            width: max-content;

            &:hover {
                transform: scale(1.03);
            }
        }

        .Innerinnputsection {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
        }

        .subdivision {
            width: 48%;
        }
    }

    .top-nav-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .text-manage {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .dropdaunlsit {
            border: 1px solid #E8EBF0;
            display: flex;
            align-items: center;
            padding: 8px 14px;
            border-radius: 6px;
            cursor: pointer;
            height: 34px;

            &:hover {
                transform: scale(1.03);
            }

        }

        .input {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #0A162B;
            /* margin-left: 1.5rem; */
            padding: 0 0px;
            width: 8rem;
            border: none;
            height: 29px;

            &::placeholder {
                color: #0A162B;
            }
        }


        .fliter-svg {
            display: flex;
            align-items: center;

            gap: 1.5rem;
            cursor: pointer;

            &:hover {
                svg {
                    transform: scale(1.03);
                }
            }

            svg {
                cursor: pointer;
            }
        }

        .shortbytextopcaity {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #8C9BB5;
            white-space: pre;
            margin-right: 15px;
        }

        .shortbytext {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #0A162B;
            margin-right: 16px;
            white-space: pre;
        }

        .dropdown-manage {
            display: flex;
            align-items: center;
            gap: 1.5rem;
        }

        .short-drop-down-orange {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 16px;
            background:  #C51E25;
            border-radius: 6px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            color: #fff;

            height: 35px;
            cursor: pointer;

            .line {
                height: 24px;
                width: 1.26px;
                background: #fff;
                height: 24px;
                margin-left: 15px;
                margin-right: 5px;
            }

            &:hover {
                transform: scale(1.03);
            }
        }

        .short-drop-down {
            position: relative;
            display: flex;
            align-items: center;
            border-left: 2px solid #0A162B40;
            padding: 0px 9px;
            padding-right: 0px;
            height: 24px;


            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

            // line {
            //     height: 24px;
            //     width: 1.26px;
            //     background: #0A162B40;


            // }



        }
    }

    .bottomtestbilling {
        display: flex;
        align-items: self-start;
    }

    .billingTextresult {
        display: flex;
        align-items: center;
        width: 347px;
        justify-content: space-between;
        margin-top: 0.6rem;
        padding: 0 0.5rem;
    }

    .tearmtext {
        color: #868DA6;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        width: 70%;
    }

    .NameTextsublist {

        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: left;
        color: #19213D;

        span {
            color: #868DA6;

        }
    }

    .tablesierigt {
        width: 100%;
        padding-top: 1.2rem;
        padding-bottom: 0rem;
        padding-left: 0rem;

    }

    .bottomtablesummary {
        display: flex;
        align-items: self-start;
    }

    .tablistitem {
        border-top: 1px solid #737373;
        border-bottom: 1px solid #737373;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.3rem;
    }

    .f-box {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    ul {
        margin-left: 17px;
    }

    li {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #737373;
        margin-bottom: 9px;

        span {
            font-weight: 600;
            color: #202020;
        }
    }

    .titlebox {

        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #737373;

        span {
            font-weight: 600;
            color: #202020;
        }
    }

    .top {
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .left {

        width: 495px;


    }

    .des {

        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        color: #737373;

    }

    .NameRed {

        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color:  #C51E25;

    }









    .labelsavelist {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin-top: 20px;
    }

    .clearlabel {
        align-items: center;
        border: 1px solid #d1d7e1;
        border-radius: 6px;
        color: #272727;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .savelabel {
        align-items: center;
        background: #37b12b;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .textSectionTop {
        align-items: center;
        display: flex;

        padding-top: 2rem;
    }

    .topName-section {
        align-items: center;
        display: flex;
        margin-bottom: 1.2rem;
    }

    .name {
        color: #08182e;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .rightside {
        width: 100%;
    }

    .infotext {
        color: #08182e;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .subdivision {
        width: 32%;
    }

    .prestingSection {
        padding: 1rem;
        background: #FFF7F3;
        display: flex;
        gap: 1%;

        .innertite {
            margin-bottom: 6px;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #121212;

        }

        .labeltext {
            background: #FFFFFF;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #121212;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 34px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            display: flex;
            padding: 0 1rem;
        }

        .textlabwl {

            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: right;
            color: #A33300;
            width: max-content;
            height: 18px;
            padding: 0px 12px;
            height: 20px;
            display: flex;
            border-radius: 6px;
            align-items: center;
            background: #FFF7F3;

        }
    }

    .title {
        //styleName: xl/Semibold;
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;

    }



    .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 10px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 20px;
        opacity: 1;
        transition: opacity 0.25s ease;
        font-weight: 600;
        font-size: 12px;
    }

    .react-toggle-track {
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: #BAC3D3 !important;

        &:hover {
            background-color: #BAC3D3 !important;
        }
    }

    .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 9px;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: 0px 0px 0px 0px #fff !important;
    }

    .react-toggle--focus .react-toggle-thumb {
        box-shadow: 0px 0px 0px 0px #fff !important;
    }

    .react-toggle--checked .react-toggle-thumb {
        left: 31px;
        border-color: transparent;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        background: white;
    }

    th,
    td {
        border: 1px solid lightgray;
        padding: 8px;
        text-align: left;

        white-space: nowrap;

        border: 0px;
        max-width: 400px;

        &:first-child {
            width: 50px;
        }

        &:nth-child(2) {
            width: 300px;
        }

        &:last-child {
            text-align: left;
            //  width: 150px;
        }
    }

    td {
        padding: 16px 15px;
        border-bottom: 1px solid #E3E7ED;
        font-size: 14px;
        color: #08182E;
    }

    th {
        background: #809FB8;
        text-overflow: ellipsis;
        color: #fff;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;

        font-weight: 500;
    }

    table tr:first-child td {
        border-top: none;
    }

    .containerpage {

        width: 100%;

        height: 300px;
        margin-bottom: 1rem;
    }

    .tWrap {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        border: 1px solid #E3E7ED;

        &__head {
            flex: 0 0 37px; // for Safari and IE
            overflow-y: scroll;
        }

        &__body {
            overflow-y: scroll;
        }
    }




    .rightside-page {

        width: calc(100% - 319px);
        background: #fff;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        padding: 1rem;
        height: 100%;
        overflow: scroll;

        .listOfcard {
            display: flex;
            align-items: self-start;
            margin-top: 1rem;
            justify-content: space-between
        }

        .in-Name {

            font-size: 32px;
            font-weight: 700;
            line-height: 43.57px;
            text-align: right;

        }

        .billNamecard {
            width: 32%;
        }

        .deatiltext {
            color: #757575;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;


        }

        .smalltextbilling {
            svg {
                margin-right: 7px;
            }

            display: flex;
            align-items: center;
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #868DA6;
            margin-bottom: 0.7rem;

        }

        .cardlist-contant-payment {
            width: 100%;
            padding: 1rem;
            border-radius: 6px;
            background: #010101;
            border-radius: 6px;
            height: 158px;

            .line {
                background: #fff;
                height: 2px;
                width: 100px;
                margin: 0.5rem 0px;
            }

            .valuelist {
                font-size: 18px;
                font-weight: 400;
                line-height: 21.94px;
                text-align: left;
                color: white;
            }

            .textlist {

                font-size: 12px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: white;
            }

        }

        .cardlist-contant {
            background: #F6F8FC;
            width: 100%;
            padding: 1rem;
            border-radius: 6px;
            border-radius: 6px;
            height: 158px;
        }

        .listNamecard {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            svg {
                margin-right: 7px;
            }

            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: #19213D;

        }

        .top-sec-add {
            display: flex;
            align-items: self-start;
            justify-content: space-between;
        }

        .textrightsidedeatil {

            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #5D6481;

            span {
                color: #19213D;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .list-section {
        height: 100%;
        overflow: scroll;
    }

    .leftside-billing {
        width: 300px;
        min-width: 300px;
        height: 100%;
        overflow: scroll;

    }

    .billing-section-inner-page {
        display: flex;
        align-items: flex-start;
        height: calc(100vh - 177px);
        justify-content: space-between;
    }

    .tab-sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tabName {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: center;
        color: #0A162B;
        width: 33.33%;
        background: #F4F4F4;
        height: 32px;
        border-radius: 6px;
        margin-bottom: 0.6rem;
        cursor: pointer;
    }

    .cardname {

        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #1F2026;
        margin-bottom: 7px;
    }

    .cards {
        width: 100%;
        border: 1px solid #E3E7ED;
        box-shadow: 0px 1px 2px 0px #00000012;
        padding: 0.7rem 0.7rem;
        background: white;
        border-radius: 6px;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            transform: scaleY(1.03);
        }
    }

    .cardLabel {

        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #58B039;
        border: 1px solid #CCEFC0;
        box-shadow: 0px 1px 2px 0px #00000012;
        background: #F0FEEB;
        padding: 0 1rem;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 27px;
    }

    .cardvalue {
        color: #333333;
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: right;

    }

    .cardsubtext {

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #767A85;

        span {
            color: #2C2C2C;

        }
    }
}