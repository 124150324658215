.mulitselectOption {
    .searchWrapper {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        min-height: 48px;
        padding: 13px 22px 8px 1.5rem;
        position: relative;
    }

    li {
        padding: 10px, 1.5rem !important;
    }

    li:hover {
        background: #0D264C !important;
        color: #fff;
        cursor: pointer;
    }

    .chip {
        align-items: center;
        background: #0D264C;
    }

    .highlightOption {
        background: transparent;
        color: #010101;
    }
}


// select option:hover {
//     background-color: red !important;
//     color: white;
//   }
.Selectiontime {
    optgroup {
        font-size: 40px;
    }

    option:checked {

        background: #0b2447;

        color: white;
    }


    select:hover option:hover {
        background: #0b2447;
        color: white;
    }

    option {
        padding: 1rem 1rem;
        font-size: 14px;
        color: #010101;
        height: 4rem;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }

        &:hover {
            box-shadow: 0 0 10px 10px #0b2447 inset !important;
            background: #0b2447 !important;
        }
    }

    select::-ms-expand {
        display: none;
    }

    select {
        padding: 1rem 1rem;
        font-size: 0.8rem;
        color: #010101;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }


}