.deletedEditingButton {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
    text-align: center;
    color: #FFFFFF;
    width: 174px;
    height: 39px;

    border-radius: 10px;
    background: #C51E25;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        margin-right: 3px
    }

    &:hover {
        transform: scale(1.03) translatex(-3px)
    }

}

.profileTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .svg {
        cursor: pointer;

        &:hover {
            transform: scale(1.1) translatex(3px)
        }
    }
}

.profiletab {
    background:  #010101;
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    gap: 2.5rem;
    padding: 0 2.5rem;
    border-radius: 10px;
    margin-top: 2rem;
    position: relative;
    z-index: 9;

    .innder {
        display: flex;
        align-items: center;
        gap: 2.5rem;
    }

    .separartor {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .itemsdata {
        border: 1px solid  #C51E25;
        border-radius: 20rem;

        cursor: pointer;
        /* Other styles */
        width: 100px;
        height: 40px;
        padding: 12px;
        width: 110px;
        height: 31px;
        display: flex;
        color: white;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;

        &:hover {
            transform: scale(1.03) translatex(-3px)
        }
    }

    .items {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;

        height: 33px;
        color: #fff;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        border-radius: 35px;
        cursor: pointer;

        &:hover {
            transform: scale(1.08) translatex(-3px)
        }
    }

}

.history-page {
    width: 100%;
    padding-top: 7px;
    margin-top: 2rem;
    // padding-bottom: 2rem;
    position: relative;
    position: relative;
    height: calc(100vh - 300px);
    overflow: scroll;

    .bottom-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0rem;
        width: 100%;
    }

    .save {
        width: 169px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #C51E25;
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 700;
        line-height: 20.72px;
        text-align: center;
        color: white;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .goback {
        width: 169px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background:  #010101;
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 700;
        line-height: 20.72px;
        text-align: center;
        color: white;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .text {
        color: #010101;
        margin-bottom: 1rem;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 18.29px;
        text-align: left;

    }

    .historytable {
        padding: 1.5rem 1.5rem;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        color: #010101;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        &:hover {
            transform: scaleY(1.1)
        }

        div {
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 500;
            line-height: 15.85px;
            text-align: left;

        }
    }
}

.action-page {
    width: 100%;
    height: 100%;
    margin-top: 2rem;

    .table-tab {
        display: flex;
        align-items: center;
        gap: 2%;
        flex-wrap: wrap;
    }

    .cardlist {
        width: 23.5%;
        height: 14rem;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        border-radius: 15px;

        &:hover {
            transform: scale(1.03) translateX(4px)
        }
    }

    .name {
        width: 110px;
        height: 110px;
        background: #D9D9D980;
        border-radius: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 500;
        line-height: 30.48px;
        text-align: center;
        color: #010101;
    }

    .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 14.63px;
        text-align: center;
        color: #010101;
        margin-top: 0.9rem;

    }
}


.handle-table-editing {
    position: relative;
    z-index: 1;
    top: -6px;

    overflow: scroll;
    height: calc(100vh - 20rem);

    .g-table-sec {


        overflow: auto;
        height: 100%;
        padding-top: 0rem;
        background-color: white;
    }

    .table-section {

        height: 100%;
    }

    table {
        border-spacing: 0px 11px;
        border-collapse: separate;
        min-width: 240%;
        table-layout: fixed;
        width: max-content;


        th {
            border-collapse: collapse;
            max-width: 200px;
            min-width: 40px;
            padding: 0 1rem;
            position: sticky;
            top: 0;
            background: #C51E25;

            z-index: 1;
            color: white;
        }
    }

    tr {
        // display: grid;
        height: 4rem;


        background: #FBFBFB;

        &:hover {
            transform: scale(1.01);
        }

        &:nth-child(odd) {
            background: #FBFBFB;
            // background: red;
        }

        &:nth-child(even) {
            background: #fff;
        }
    }

    td {
        padding: 1rem 1rem;
        position: relative;
        max-width: 200px;
        min-width: 40px;
        font-size: 13px;

        // border: 1px solid #e5e5e5;

        // white-space: normal;
        // word-wrap: break-word;

        // position: relative;
        // display: flex;
        // align-items: center;
        // border-right: none;
        // border-top: none;
        // font-size: 0.85rem;


        &:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
        }

        &:nth-last-child(1) {
            border-radius: 0px 10px 0px 10px;
        }
    }

    thead {


        th {

            padding: 0 1rem;


            &:nth-child(1) {
                border-radius: 0px 0px 0px 10px;
            }

            &:nth-last-child(1) {
                border-radius: 0px 0px 10px 0px;
            }
        }

        tr {

            //display: grid;

            &:nth-child(1) {

                font-family: Montserrat;
                font-size: 12px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
                color: white;

                z-index: 9;
                white-space: nowrap;
                height: 40px;
                background: #010101;
                position: relative;
                top: -14px;

                &:hover {
                    transform: scale(1) !important;
                }

            }
        }
    }
}