.workflowPage {
  padding: 6rem 0;
  // height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  // align-items: center;

  & > .workflow {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    & > .platformTitle {
      background: #8d5407;
      color: var(--main-color);
      padding: 5px 10px;
      font-size: 1.5rem;
      font-weight: 500;
      width: max-content;
      border-radius: 3px;
    }

    & > .title {
      font-size: 3rem;
      font-weight: 500;
      width: 100%;
      // max-width: 800px;
      text-align: center;
      font-family: "Montserrat Alternates";
    }

    & > .desc {
      font-size: 1.5rem;
      font-weight: 380;
      font-family: "Montserrat Alternates";
      text-align: center;
    }

    & > .linkBtn {
      font-family: "Montserrat Alternates";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.6rem;
      cursor: pointer;
      margin-top: 2rem;

      & > span {
        background: rgb(34 40 30/1);
        padding: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 300;
        transition: all linear 0.3s;
      }

      &:hover {
        & > span {
          background: rgb(243, 243, 101);
          color: rgb(34 40 30/1);
          translate: 10px;
        }
      }
    }

    & > .lottieAnimation {
      width: 100%;
      margin-top: 3rem;

      & > div {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .workflowPage {
    flex-direction: column;

    & > .workflow {
      padding: 0 1.5rem;
    }
  }
}
