.bookingDashboard {
    background: #fff;
    height: calc(100vh - 107px);
    margin: 2rem;
    padding: 1rem;
    border-radius: 6px;

    .labelbutton {

        font-size: 14px;
        font-weight: 400;
        line-height: 21.78px;
        text-align: center;
        color: #5E7398;
        height: 34px;
        padding: 0 1rem;
        background: #EFF0F6;
        width: max-content;
        border-radius: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }

    }

    .tabsection {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #E6E6E6;

        .tabs {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: #44536E;
            padding: 4px;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .body-table {
        background: white;
        box-shadow: 0px 4px 20px 0px #D1D7E140;
        border: 1px solid #D1D7E1;

        border-radius: 6px;
        margin-bottom: 0.7rem;
        cursor: pointer;

        &:hover {
            box-shadow: rgba(180, 180, 184, 0.2) 0px 7px 29px 0px;

            // .tablecontent{
            //     transform: scaleY(1.05);
            // }
            //  transform: scaleY(1.05);
        }
    }

    .tablecontent {
        display: grid;
        align-items: center;
        position: relative;
        height: 67px;
        padding: 0rem 1rem;

        .inside-section {
            min-width: 120px;
            // width: 10;
            // width: 100%;
            padding-right: 17px;
            font-size: 14px;
            color: #1F2026;
            font-weight: 500;
            display: flex;
            align-items: center;
            height: 100%;

            .startselection {
                position: relative;

                .belowContent {
                    position: absolute;
                    background: #FFE3D7;
                    width: 100%;
                    left: 0;
                    padding: 0 16px;
                    top: -11px;
                    border-radius: 6px;
                    border: 1px solid rgb(252, 79, 0);
                    padding: 7px 15px;
                    z-index: 9;
                    border-radius: 6px;

                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color:  #C51E25;
                    cursor: pointer;


                    &:hover {
                        transform: scale(1.03);
                    }
                }


            }
        }
    }

    .tooglebooking {
        width: 100%;
        background: white;
        border-top: 1px solid #D1D7E1;
        margin-top: 1rem;
        padding-top: 1rem;
        display: flex;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .innerleft {
            margin-left: 39px;
        }

        .right {
            width: max-content;
            width: calc(100% - 21rem);
            padding-left: 35px;


            .textslide {
                display: flex;
                align-items: start;
                gap: 3rem;
                margin-top: 1rem;

                .online {
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    border-radius: 1rem;

                    background: #67DAFF;

                }

                .date {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    color: #767A85;
                    width: max-content;
                    display: flex;
                    align-items: center;
                }

                .notes {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    color: #1F2026;

                }
            }

            .steps-section {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0, 5rem;

                .stepLine {
                    width: 100%;
                    height: 5px;
                    background: #EFF0F6;
                    margin-top: 13px;

                }

                .cricle {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .stepcirle {
                    width: 32px;
                    height: 32px;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    background: #EFF0F6;
                    color: #272727;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20rem;

                    &:hover {
                        transform: scale(1.03);
                        cursor: pointer;
                    }
                }

                .days {
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 3px;
                    color:
                        #767A85;
                }
            }

            .top-Nmae {
                display: flex;
                align-items: center;

                .name {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    color: #1F2026;
                    margin: 0 8px;
                }

                .newclient {
                    border: 1px solid #58B039;
                    width: 104.47px;
                    height: 18.66px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 6px;
                    color: #58B039;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;
                }


            }
        }

        .left {
            border-right: 1px solid #D1D7E1;
            padding-right: 2rem;
            width: 21rem;

            .reprotlabel {
                width: max-content;
                background:  #C51E25;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                color: white;
                margin-top: 2rem;
                font-size: 13px;
                height: 38px;
                padding: 0 1.3rem;
                border-radius: 6px;
                display: flex;
                align-items: center;

                &:hover {
                    transform: scale(1.03);
                    cursor: pointer;
                }
            }

            .dateofreg {
                color: #767A85;
                font-size: 12px;
                font-family: Montserrat;
                margin-bottom: 3px;
                font-weight: 500;
                margin-top: 1rem;
            }

            .alldateofreg {
                color: #1F2026;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 600;
            }

            .imagesection {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-top: 0.5rem;
            }

            .icon {
                border-radius: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 1px 2px 0px #00000012;
                border: 1px solid #E6E6E6;
                height: 40px;
                width: 40px;

                &:hover {
                    transform: scale(1.03);
                    cursor: pointer;
                }
            }

            .top-Nmae {
                display: flex;
                align-items: center;

                .name {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    color: #1F2026;
                    margin: 0 8px;
                }

                .newclient {
                    border: 1px solid #9FE8FF;
                    width: 86.47px;
                    height: 18.66px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 6px;
                    color: #06C1FD;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;
                }

                .circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 60px;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    color: white;
                    background: #D1D7E1;

                }
            }
        }
    }

    .table-headersection {
        background: #F5F7FA;
        display: grid;
        border-radius: 7px;
        color: #44536E;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        line-height: 30px;
        position: relative;
        text-align: left;
        white-space: nowrap;
        z-index: 9;
        margin-top: 1.5rem;
        padding: 5px 1rem;
        margin-bottom: 0.9rem;

        div {
            min-width: 120px;
            width: 10;
            // width: 100%;
            padding-right: 17px;
        }
    }

    .tablesection-booking {
        // display: grid;
        // height: calc(100% - 217px);
        // overflow: scroll;


        tr {
            background: #fbfbfb;
            border-radius: 7px;

            margin-top: .5rem;

            border: 8px solid #D1D7E1;

        }

        td {
            font-size: 12px;
            max-width: 200px;
            min-width: 40px;
            padding: 1rem;

            position: relative;

            text-transform: capitalize;
        }

        .dot {
            width: 10px;
            height: 10px;
            background: #BAC3D3;
            border-radius: 20px;
        }

        .stauscheck {
            display: flex;
            align-items: center;

            .dotline {
                background: #F1F5F8;
                width: 31px;
                height: 10px;


            }
        }

        .react-toggle--checked .react-toggle-track {
            background-color:  #C51E25;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            color: white;

        }

        .react-toggle-track-x {
            position: absolute;
            width: 10px;
            height: 10px;
            top: 10px;
            bottom: 0px;
            margin-top: auto;
            margin-bottom: auto;
            line-height: 0;
            right: 20px;
            opacity: 1;
            transition: opacity 0.25s ease;
            font-weight: 600;
            font-size: 12px;
        }

        .react-toggle-track {
            width: 50px;
            height: 24px;
            padding: 0;
            border-radius: 30px;
            background-color: #BAC3D3;

            &:hover {
                background-color: #BAC3D3;
            }
        }

        .react-toggle-track-check {
            position: absolute;
            width: 14px;
            height: 10px;
            top: 9px;
        }

        .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
            box-shadow: 0px 0px 0px 0px #fff !important;
        }

        .react-toggle--focus .react-toggle-thumb {
            box-shadow: 0px 0px 0px 0px #fff !important;
        }

        .react-toggle--checked .react-toggle-thumb {
            left: 31px;
            border-color: transparent;

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 0px !important;
            }
        }

        .react-toggle-track {
            width: 60px !important;
        }

        table {
            border-collapse: initial;
            border-spacing: 0 11px;
            min-width: 240%;
            table-layout: fixed;
            width: -webkit-max-content;
            width: max-content;
        }

        table th {
            background: #F5F7FA;

            border-collapse: collapse;
            color: #44536E;

            max-width: 200px;
            min-width: 40px;
            padding: 0 1rem;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        thead tr:first-child {
            background: #F5F7FA;

            border-radius: 7px;
            color: #44536E;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            height: 40px;
            line-height: 30px;
            position: relative;
            text-align: left;
            white-space: nowrap;
            z-index: 9;
        }
    }

    .fliter-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        .react-datepicker__day--keyboard-selected {
            background:  #C51E25 !important;
            border-radius: 10rem !important;
        }

        .react-datepicker__day {
            &:focus-visible {
                outline: -webkit-focus-ring-color auto 0px !important;
            }
        }

        .react-datepicker__day--selected {
            border-radius: 10.3rem;
            background-color:  #C51E25 !important;
            color: #fff;
        }

        .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {

            display: none;
        }

        .react-datepicker__header {
            text-align: center;
            background-color: white;
        }

        .react-datepicker {

            font-size: 0.8rem;
            background-color: #fff !important;
            color: #000;
            border: 0px solid !important;



        }

        .react-datepicker__input-container {
            input {
                display: none !important;
            }
        }

        .react-datepicker-popper {

            position: absolute;
            inset: auto !important;
            border: 1px solid #E8EBF0;
            border-radius: 6px;
            right: 0px !important;
            top: 43px !important;
            z-index: 999;
            transform: translate3d(0px, 0px, 0px) !important;
        }

        .react-datepicker-popper[data-placement^=bottom] {
            padding-top: 0px !important;
        }

        .react-datepicker__day,
        .react-datepicker__time-name {
            color: #08182E !important;
            display: inline-block;
            width: 1.7rem;
            line-height: 1.7rem;
            text-align: center;
            margin: 0.416rem;
            font-size: 14px;
        }

        .react-datepicker__day--keyboard-selected:hover {
            background:  #C51E25 !important;
            border-radius: 10rem !important;
        }

        .label-section-fliter {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .location-manage {
            display: flex;
            align-items: center;
            gap: 7px;
        }

        .short-drop-down-fliter {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 15px;
            background: #ffff;
            border-radius: 6px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 19px;
            color: #0A162B;
            border: 1px solid #E8EBF0;
            border-radius: 6px;
            height: 37px;
            cursor: pointer;

            .line {

                width: 1.26px;
                background: #0A162B40;
                height: 20px;
                margin-left: 15px;
                margin-right: 5px;
            }
        }

        .label-month {
            color: #0A162B;
            white-space: pre;
            background: #fff;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            line-height: 19px;
            /* color: #0A162B; */
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 6px;
            padding: 0px 15px;
            max-width: fit-content;

            &:hover {
                transform: scale(1.03);
            }




        }

        .svg-gap {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-left: 17px;

        }

        .locationopcaticy {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 19px;
            color: #8C9BB5;


        }

        .label-days {

            color: #0A162B;

            background: #fff;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            line-height: 19px;
            /* color: #0A162B; */
            height: 37px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            padding: 0px 10px;
            max-width: fit-content;
            border: 1px solid #E8EBF0;

            &:hover {
                transform: scale(1.03);
            }
        }

        .label-section {
            display: flex;
            align-items: center;

            border-bottom: 1px solid #D1D7E1;
            width: calc(100% - 321px);
        }

        .label {
            color: #475F8A;
            background: #E8EBF0;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            line-height: 19px;
            /* color: #0A162B; */
            height: 37px;
            display: flex;
            align-items: center;
            border-radius: 8px 8px 0 0px;
            padding: 0px 19px;
            max-width: fit-content;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .top-nav-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text-manage {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .dropdaunlsit {
            border: 1px solid #E8EBF0;
            display: flex;
            align-items: center;
            padding: 8px 14px;
            border-radius: 6px;
            cursor: pointer;




        }

        input {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #0A162B;
            /* margin-left: 1.5rem; */
            padding: 0 0px;
            width: 8rem;
            border: none;
            height: 29px;

            &::placeholder {
                color: #0A162B;
            }
        }


        .fliter-svg {
            display: flex;
            align-items: center;

            gap: 1.5rem;
            cursor: pointer;

            &:hover {
                svg {
                    transform: scale(1.03);
                }
            }

            svg {
                cursor: pointer;
            }
        }

        .shortbytextopcaity {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #8C9BB5;
            white-space: pre;
            margin-right: 15px;
        }

        .shortbytext {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #0A162B;
            margin-right: 40px;
            white-space: pre;
        }

        .dropdown-manage {
            display: flex;
            align-items: center;
            gap: 1.5rem;
        }

        .short-drop-down-orange {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 16px;
            background:  #C51E25;
            border-radius: 6px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            color: #fff;

            height: 35px;
            cursor: pointer;

            .line {
                height: 24px;
                width: 1.26px;
                background: #fff;
                height: 24px;
                margin-left: 15px;
                margin-right: 5px;
            }

            &:hover {
                transform: scale(1.03);
            }
        }

        .short-drop-down {
            position: relative;
            display: flex;
            align-items: center;
            border-left: 2px solid #0A162B40;
            padding: 0px 9px;
            padding-right: 0px;
            height: 24px;


            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

            // line {
            //     height: 24px;
            //     width: 1.26px;
            //     background: #0A162B40;


            // }



        }
    }

}

.tableparenttanle {
    height: calc(100% - 153px);
    overflow: scroll;
    width: 100%;
}

.tablesection-booking-full {

    min-width: max-content;

}



.dropdownlistgobel {
    position: absolute;
    left: 0;
    background: white;
    z-index: 999;
    width: max-content;

    border: 1px solid #E8EBF0;

    border-radius: 6px;
    top: 46px;
    box-shadow: 0px 4px 4px 0px #00000040;

    width: 100%;
    padding-bottom: 10px;

    .inner {
        border: 1px solid #E8EBF0;

        border-radius: 6px;
        top: 0px;
        box-shadow: 0px 4px 4px 0px #00000040;

        width: 15rem;
        display: block;
        position: absolute;
        right: -232px;

        margin-top: 0 !important;
        height: fit-content;
        padding-top: 0px;
        max-height: 279px;
        overflow: scroll;

        &:hover {
            background: white !important;
        }

        div {}

        .text {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #1F2026;
            height: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            background: white;
            margin-top: 9px;
            padding: 9px 11px;

            &:hover {
                background:  #C51E25 !important;
                color: white;
                border-radius: 6px;
            }
        }
    }

    div {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #1F2026;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background: white;
        margin-top: 9px;
        padding: 9px 11px;
        position: relative;
        white-space: pre;

        &:hover {
            background:  #C51E25;
            color: white;
            transform: scale(1.02);
        }

    }
}

.customdropdownlist {
    position: relative;


}

.listselected-booking-top {
    display: flex;
    align-items: self-start;
    padding-bottom: 5px;
    width: 100%;
    overflow: scroll;
    gap: 1rem;
    margin-left: 1rem;

    .textlabel {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        border-radius: 6px;
        color: rgba(68, 83, 110, 1);
        background: rgba(209, 215, 225, 1);
        height: 35px;
        cursor: pointer;
        padding: 0 1rem;
        white-space: pre;

        &:hover {
            transform: scale(1.02);
        }

        svg {
            margin-left: 7px;

        }
    }
}

.colorbutton {
    color: white;
    padding: 0 1rem;
    border-radius: 6px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.02);
    }
}


.therpaymodel {
    width: 474px;
    height: 296px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #1F2026;
        margin-bottom: 1rem;
        margin-top: 0.4rem;
    }

    .top-title-name {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        width: 474px;
        height: 65px;
        top: 202px;
        left: 483px;
        gap: 0px;
        border-radius: 15px 15px 0px 0px;

        background:  #010101;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    textarea {
        width: 421px;
        height: 295px;
        margin: 23px 23px;
        border-radius: 5px;
        padding: 1rem;
        border: 1px solid #E5E5E5;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #5F6163;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }

    label {
        width: 161px;
        height: 40px;

        margin-top: 0.5rem;

        border-radius: 6px;
        background:  #C51E25;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: center;
        margin-bottom: 0;

        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }
    }
}

.ReactModal__Overlay {
    background-color: #3a34341f !important;
    z-index: 999 !important;
}




@media only screen and (max-width: 1400px) {

    .bookingDashboard .top-nav-list .dropdaunlsit {

        padding: 8px 8px;

    }

    .bookingDashboard .top-nav-list input {

        width: 6rem;

    }

    .bookingDashboard .top-nav-list .short-drop-down-orange {

        padding: 0px 8px;
    }

    .dropdownlistgobel div {
        font-size: 12px;
    }

    .bookingDashboard .top-nav-list .shortbytext {

        margin-right: 20px;
    }

    .bookingDashboard .top-nav-list .text-manage {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .bookingDashboard .top-nav-list .dropdown-manage {
        display: flex;
        align-items: center;
        gap: 0.55rem;
    }

}