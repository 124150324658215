.bookingDrawerPage {
    width: 727px;
    background: white;
    position: absolute;
    top: 0;
    height: 100vh;
    right: 0;
    z-index: 999999;
    box-shadow: 0px 0px 12px 0px #6D6D6D1F;
    padding-top: 52px;
    overflow: scroll;

    input[type=radio] {
        accent-color:  #C51E25;

    }

    .textFortext {
        margin-bottom: 0.5rem;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;

    }

    .dateSection {
        display: flex;
        align-items: center;
        gap: 1rem;

    }

    .packagelines {
        background: #9CAABF;
        height: 3px;
        width: 100%;
        margin-top: 20px;
    }

    .startapitime {
        border: 1px solid #D1D7E1;
        box-shadow: 0px 1px 2px 0px #1018280D;
        margin-top: 9px;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        width: max-content;
        height: 40px;

        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #7587A7;
        background: #D1D7E1;
    }

    .id {
        color: #473D3E;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        margin-left: 13px;
        text-align: left;
        background: #F8F8F8;
        padding: 3px 8px;
        width: max-content;
        border-radius: 6px;
    }

    .detailpagedrawershow {
        border: 1px solid #D1D7E1;
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: self-start;
        padding: 0.8rem;
        padding-bottom: 0rem;
        position: relative;

        svg {
            position: absolute;
            top: 0.8rem;
            right: 0.8rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.02);
            }
        }

        .bigtext {

            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #1F2026;

        }

        .smalltext {

            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #7587A7;

        }

        .maininner {
            margin-left: 10px;
        }

        .innertext {

            margin-bottom: 0.8rem;
        }

        .cricle {
            color: #FFFFFF;
            background: #8C9BB5;
            border: 0.83px solid #D1D7E1;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: -0.01em;
            text-align: center;
            width: 40px;
            height: 40px;
            border-radius: 100rem;


        }

        .leftside {
            width: 50%;
            display: flex;
            align-items: self-start;
        }
    }

    .inner-section-toggle {
        padding: 1rem;
    }

    .react-datepicker__day {
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }

    .label-section {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #aeaeae;

    }

    .top-dection-drawer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

    }

    .react-datepicker__day--keyboard-selected {
        background: transparent;
        border-radius: 10rem !important;
    }

    .react-datepicker__day--keyboard-selected:hover {
        background:  #C51E25 !important;
        border-radius: 10rem !important;
    }

    .clientsearchinput {
        display: flex;
        align-items: center;
        gap: 1rem;

        .Search-button {
            border: 1px solid  #C51E25;
            width: 100px;
            height: 44px;
            color:  #C51E25;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;

            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.02em;


        }

        input {
            background: #F5F7F9;
            border: 1px solid #D1D7E1;

            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #7587A7;
            padding-left: 1rem;
            width: 344px;

            height: 41px;
            display: flex;
            align-items: center;
            border-radius: 6px;
        }
    }


    .leftside {
        margin-right: 1rem;
        width: 344px;
    }

    .main-show {
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .labellistbox {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1.3rem;
    }

    .bottmsection {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
        border-top: 1px solid #E3E7ED;
        padding: 1.4rem 1rem;
    }

    .decline {
        box-shadow: 0px 1px 2px 0px #00000012;
        border: 1px solid #D1D7E1;
        border-radius: 6px;
        height: 39px;

        svg {
            margin-right: 7px;
        }

        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0rem 1.2rem;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #7587A7;
        width: max-content;
    }

    .listitemNamedrop {
        display: flex;
        align-items: center;
        color: #7587A7;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;

        &:hover {
            background:  #C51E25;
            color: #fff;
            transform: scale(1.02);
            font-style: 600;
            border-radius: 6px;
        }
    }

    .labelseletedcheck {
        height: 32px;
        margin-top: 0.7rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        background: #F5F7F9;

        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #7587A7;
        width: max-content;

        &:hover {
            transform: scale(1.02);
        }

        svg {
            margin-left: 20px;
        }
    }

    .selectServices {

        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #0A162B;

    }

    .ClientDetails {

        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 0.7rem;
        text-align: left;
        color: #08182E;
        margin-top: 1.4rem;
    }

    .label {
        color: #475F8A;
        background: #E8EBF0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        line-height: 19px;
        /* color: #0A162B; */
        height: 37px;
        display: flex;
        align-items: center;
        border-radius: 8px 8px 0 0px;
        padding: 0px 19px;
        max-width: fit-content;

        &:hover {
            transform: scale(1.03);
        }
    }

    .onetwo {

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #08182E;
        margin-right: 12px;
    }

    .droplistselect {
        padding: 0 1.3rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #7587A7;
        background: #F5F7F9;
        border: 1px solid #D1D7E1;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 9px;
        border-radius: 6px;
        margin-bottom: 1.3rem;


    }

    .leftandright {
        border: 1px solid #D0D5DD;
        box-shadow: 0px 2px 6px 0px #81818114;
        cursor: pointer;
        padding: 0.5rem 0.8rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 12px;
        width: max-content;
    }

    .main-date-section {
        border: 1px solid #D4D7E3;
        border-radius: 6px;
        padding: 0 18px;
        padding-bottom: 25px;
        width: 333px;
        margin-top: 23px;

        .react-datepicker-popper[data-placement^=bottom] {
            padding-top: 0px !important;
        }

        .react-datepicker__day--selected {
            border-radius: 10.3rem;
            background-color:  #C51E25 !important;
            color: #fff;
        }

        .react-datepicker__day--in-range {
            background-color:  #C51E25 !important;
            color: #fff !important;
        }

        .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
            background-color:  #C51E25 !important;
            color: #fff !important;
        }

        .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {

            display: none;
        }

        .react-datepicker__header {
            text-align: center;
            background-color: white;
        }

        .react-datepicker {

            font-size: 0.8rem;
            background-color: #fff !important;
            color: #000;
            border: 0px solid !important;



        }

        .react-datepicker__input-container {
            input {
                display: none !important;
            }
        }

        .react-datepicker-popper {
            position: relative !important;
            inset: 0px auto auto 0px;
            transform: translate3d(0px, 0px, 0px) !important;
        }

        .react-datepicker__day,
        .react-datepicker__time-name {
            // color: #08182E !important;
            display: inline-block;
            width: 1.7rem;
            line-height: 1.7rem;
            text-align: center;
            margin: 0.416rem;
            font-size: 14px;
        }

        .react-datepicker__day {
            // color: #FFFFFF !important;
        }
    }


    .dropdown-partent-div {
        width: 100%;
        position: relative;

    }

    .timeshow {
        position: relative;
        margin-top: 23px;

        .crirlce {
            cursor: pointer;
            background: #E8EBF0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 4rem;
            justify-content: center;
            position: absolute;
            top: -10px;
            right: -11px;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .timeNamelistdivcalendar {
        display: flex;
        align-items: center;
        color: #7587A7;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid #D1D7E1;
        height: 3rem;
        border-radius: 6px;
        margin-top: 1.2rem;


    }

    .timeNamelistdiv {
        display: flex;
        align-items: center;
        color: #7587A7;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid #D1D7E1;
        height: 3rem;
        border-radius: 6px;
        margin-bottom: 0.5rem;

        &:hover {
            background:  #C51E25;
            color: #fff;
            transform: scale(1.02);
            font-style: 600;
            border-radius: 6px;
        }
    }

    .divselectlist {
        border-radius: 6px;
        background: #fff;
        padding: 0.5rem;
        position: absolute;
        top: 44px;
        width: 350px;
        left: 0;
        margin: auto;
        right: 0;
        z-index: 1;
        white-space: break-spaces;
        border: 1px solid #D1D7E1;
        // border: 1px solid #E5E5E5;

        height: 142px;
        overflow: scroll;


        input {
            color: #7587A7;
            background: #F5F7F9;
            border: 1px solid #D1D7E1;
            height: 35px;
            padding: 0 1.3rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            width: 100%;
            border-radius: 6px;
            margin-bottom: 10px;
        }
    }



    .mainline {
        background: #D0D5DD;
        width: 15px;
        height: 2px;
        margin: 0 10px;

    }

    .timedropdowndata {
        display: flex;
        align-items: center;
        gap: 1rem;

        .timedata {
            border: 1px solid #D1D7E1;
            box-shadow: 0px 1px 2px 0px #1018280D;
            background: #F5F7F9;
            display: flex;
            align-items: center;
            border-radius: 6px;
            padding: 0.5rem;

            .line {
                background: #D0D5DD;
                width: 2px;
                height: 24px;
                margin: 0 12px;

            }



            .ampm {
                color: #8C9BB5;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }

            .name {
                color: #08182E;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;


            }
        }
    }



    .timeavilable {
        //styleName: Inter-Medium;
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: left;
        color: #0A162B;

    }

    .react-datepicker__current-month {
        color: #1F2026 !important;
        font-weight: 600 !important;
    }

    .react-datepicker__day-name {
        color: #1F2026 !important;
    }

    .react-datepicker__day-names {
        margin-bottom: -8px;

        div {
            color: #767A85 !important;
        }
    }

    .topName-section {
        display: flex;
        align-items: center;
        // margin-bottom: 1.2rem;

        .name {

            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: left;
            color: #08182E;

        }

        .id {
            color: #473D3E;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            margin-left: 13px;
            text-align: left;
            background: #F8F8F8;
            padding: 3px 8px;
            width: max-content;
            border-radius: 6px;
        }
    }


}