.global-dashboard {
    padding: 2rem;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-width: 1200px;
    margin: auto;

    .drowpside-right {
        /* border: 1px solid #E9EBF3; */
        padding: 1rem;
        border-radius: 10px;
        /* position: absolute; */
        background: white;
        z-index: 99;
        top: 46px;
        right: 0rem;
        height: calc(100vh - 7px);
        overflow: scroll;
        padding-top: 5rem;
        width: 100%;
        z-index: 999999;
    }

    .label-box-header {
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #010101;
        margin-bottom: 2rem;
        text-transform: capitalize;
    }

    .right-side {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .top-section-golbal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        position: relative;
    }

    .shortBy {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 15.85px;
        text-align: left;
        color: #010101;

    }

    .addLabel {
        background: #C51E25;

        height: 39px;
        padding: 1.1rem;
        padding: 1.1rem 2.5rem;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.85px;
        text-align: center;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: min-content;
        white-space: no-wrap;
        white-space: nowrap;
        flex-wrap: nowrap;
        cursor: pointer;

        svg {
            margin-right: 4px;
        }

        &:hover {
            transform: scale(1.03);
        }
    }

    .dropdownlist {
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        height: 39px;
        padding: 1.1rem;
        border-radius: 5px;
        border: 0.5px solid #E9EBF3;
        width: min-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #010101;

        svg {
            margin-left: 16px;
        }

        &:hover {
            transform: scale(1.03);
        }

    }

    .left-side {
        display: flex;
        align-items: center;
        gap: 1rem;

    }

    .label-box {
        border: 0.5px solid #E9EBF3;
        height: 39px;
        font-family: Montserrat;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        text-align: left;
        color: #010101;
        text-transform: capitalize;
        border-radius: 5px;
        border-radius: 5px;
        width: min-content;
        display: flex;
        align-items: center;
        padding: 1.1rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    tbody {
        position: relative;
        top: 0.5rem;

    }

    .handle-table {
        border: 1px solid #E9EBF3;
        padding-top: 1rem;
        border-radius: 10px;
        height: calc(100vh - 237px);
    }

    .g-table-sec {
        padding: 1rem;
        border-radius: 10px;
        overflow: auto;
        height: 100%;
        padding-top: 0rem;
        background-color: white;
    }

    .table-section {

        height: 100%;
    }

    table {
        border-spacing: 0px 11px;
        border-collapse: separate;
        min-width: 240%;
        table-layout: fixed;
        width: max-content;


        th {
            border-collapse: collapse;
            max-width: 200px;
            min-width: 40px;
            padding: 0 1rem;
            position: sticky;
            top: 0;
            background: #010101;
            z-index: 1;
            color: white;
        }
    }

    tr {
        // display: grid;
        height: 4rem;
        margin-top: 0.5rem;
        border-radius: 10px;
        border-radius: 10px;
        background: #FBFBFB;

        &:hover {
            transform: scale(1.01);
        }

        &:nth-child(odd) {
            background: #FBFBFB;
            // background: red;
        }

        &:nth-child(even) {
            background: #fff;
        }
    }

    td {
        padding: 1rem 1rem;
        position: relative;
        max-width: 200px;
        min-width: 40px;
        font-size: 13px;
        text-transform: capitalize;
        // border: 1px solid #e5e5e5;

        // white-space: normal;
        // word-wrap: break-word;

        // position: relative;
        // display: flex;
        // align-items: center;
        // border-right: none;
        // border-top: none;
        // font-size: 0.85rem;


        &:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
        }

        &:nth-last-child(1) {
            border-radius: 0px 10px 10px 0px;
        }
    }

    thead {
        border-radius: 10px;

        th {

            padding: 0 1rem;


            &:nth-child(1) {
                border-radius: 10px 0px 0px 10px;
            }

            &:nth-last-child(1) {
                border-radius: 0px 10px 10px 0px;
            }
        }

        tr {

            //display: grid;

            &:nth-child(1) {

                font-family: Montserrat;
                font-size: 12px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
                color: white;
                border-radius: 10px;
                z-index: 9;
                white-space: nowrap;
                height: 40px;
                background: #010101;
                position: relative;

                &:hover {
                    transform: scale(1) !important;
                }

            }
        }
    }
}



// * {
//     box-sizing: border-box;
// }

// table {
//     border-collapse: collapse;
//     width: 100%;
//     table-layout: fixed;
// }

// td,
// th {
//     padding: 5px;

//     border-right: 1px solid #ccc
// }

// thead tr {
//     background: #888;
//     color: #eee;
//     display: grid;
//     position: relative;
// }